@charset "UTF-8";

// TailwindCSS
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

.auth {
    background: url("/assets/img/login.jpeg");
    background-size: cover;
}

.alert-animation {
    animation: cssAnimation 2s 0s forwards;
}

@keyframes cssAnimation {
    to   {
        visibility: hidden;
    }
}
